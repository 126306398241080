export interface BuildInfo {
  buildTime: string;
  buildNumber: string;
  commit: string;
  branch: string;
  environment: string;
}

const buildInfo: BuildInfo = {
  environment: 'stage',
  buildTime: '',
  buildNumber: '2',
  commit: '98424aad7d0c3b8cacd86daa000e5491396d6899',
  branch: 'origin/stage'
};

export const environment = {
  production: false,
  clientUrl: 'https://app.stage.learn.smoothstack.com',
  apiUrl: 'https://api.stage.learn.smoothstack.com/api/v1',
  azureClientId: 'e9a55099-2a1f-49ac-834c-d57877cab455',
  azureTenantId: '7824f42c-45bd-47d8-8d15-275c536fa0a2',
  redirectUri: 'https://app.stage.learn.smoothstack.com/login',
  postLogoutRedirectUri: 'https://app.stage.learn.smoothstack.com/login',
  buildInfo: buildInfo
};
